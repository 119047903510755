<template>
  <div id="product">
    <el-dialog
      :title="airFormTitle"
      width="600px"
      :visible.sync="airDialogVisible"
      :close-on-click-modal="false"
      @close="airDialogClose"
      ><el-form
        ref="airFormRef"
        :model="airForm"
        :rules="productFormRules2"
        label-position="right"
        label-width="180px"
      >
        <el-form-item label="检查日期" prop="checkTime">
          <el-date-picker
            v-model="airForm.checkTime"
            type="datetime"
            placeholder="选择检查日期"
            value-format="yyyy-MM-dd hh:mm:ss"
            clearable
            :readonly="handleType === 'detail'"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="清洁检查内容" prop="checkContent">
          <el-input
            v-model="airForm.checkContent"
            placeholder="请输入清洁检查内容"
            type="textarea"
            clearable
            :readonly="handleType === 'detail'"
          />
        </el-form-item>
        <el-form-item label="实际操作" prop="actualOperation">
          <el-input
            v-model="airForm.actualOperation"
            placeholder="请输入实际操作"
            type="textarea"
            clearable
            :readonly="handleType === 'detail'"
          />
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input
            v-model="airForm.remarks"
            placeholder="请输入备注"
            type="textarea"
            clearable
            :readonly="handleType === 'detail'"
          />
        </el-form-item>
        <el-form-item
          label="复核人"
          prop="remarks"
          v-if="handleType === 'detail'"
        >
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            v-if="airForm.status === 1"
            @click.stop="handleConform(airForm.id)"
          >
            复核
          </el-button>
          <el-input
            v-model="airForm.reviewer"
            type="textarea"
            clearable
            v-else
            :readonly="handleType === 'detail'"
          />
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="airDialogVisible = false"> 取 消 </el-button>
        <el-button type="primary" @click="productFormSubmit"> 确 定 </el-button>
      </div>
    </el-dialog>
    <el-dialog
      width="90%"
      title="水系统消毒记录"
      :visible.sync="innerVisible"
      :close-on-click-modal="false"
      append-to-body
    >
      <el-form
        ref="productFormRef"
        :model="airForm"
        label-position="right"
        label-width="150px"
      >
        <el-form-item label="设备名称" prop="deviceName">
          <el-input v-model="airForm.deviceName" clearable readonly />
        </el-form-item>
        <el-form-item label="设备编号" prop="deviceNo">
          <el-input
            v-model="airForm.deviceNo"
            placeholder="请输入设备编号"
            clearable
            readonly
          />
        </el-form-item>
      </el-form>
      <el-table :data="airForm.airDetailList" style="width: 100%">
        <el-table-column
          prop="disinfectionObject"
          label="清洗消毒对象"
          align="center"
        />
        <el-table-column
          prop="disinfectionDate"
          label="清洗消毒日期"
          align="center"
        />
        <el-table-column
          prop="cleaningTime"
          label="清洗剂/清洗时间"
          align="center"
        />
        <el-table-column label="消毒方法/时间" align="center">
          <el-table-column
            prop="startTime"
            label="臭氧发生器开关机时间"
            align="center"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.startTime }}--{{ scope.row.endTime }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="runCondition"
            label="臭氧发生器运行情况"
            align="center"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.runCondition === 1 ? "正常" : "异常" }}</p>
            </template>
          </el-table-column>
          <el-table-column
            prop="releaseWater"
            label="消毒完成后全部放掉管路中纯化水"
            align="center"
          >
            <template slot-scope="scope">
              <p>{{ scope.row.runCondition === 1 ? "是" : "否" }}</p>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="operator" label="操作人" align="center" />
        <el-table-column prop="confirmor" label="确认人" align="center" />
        <el-table-column prop="remarks" label="备注" align="center" />
        <el-table-column
          label="操作"
          align="center"
          v-if="airForm.status === 1"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="small"
              @click.stop="handleConform(scope.$index, scope.row, airForm.id)"
            >
              确认
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer">
        <el-button @click="innerVisible = false">关闭</el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="操作人">
        <el-input
          v-model="searchForm.operator"
          placeholder="请输入操作人"
          clearable
        />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="waterList.list"
      row-key="id"
      :cell-style="{ padding: '6px' }"
      :header-cell-style="{ background: '#f8f8f9' }"
      :height="getTableHeight()"
    >
      <el-table-column prop="checkTime" label="检查记录时间" />
      <el-table-column prop="checkContent" label="清洁内容" />
      <el-table-column prop="actualOperation" label="操作内容" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="waterList.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  getMaskMachineList,
  deleteMaskMachineList,
  confirmMaskMachineList,
  addMaskMachineDetail,
  getMaskMachineInfoDetail,
  updateMaskMachineDetail,
} from "@/api/produce/produce.js";

export default {
  data() {
    return {
      airDialogVisible: false,
      innerVisible: false,
      airFormTitle: "",
      airForm: {},
      productPage: {
        list: [],
        total: 0,
      },
      waterList: {
        list: [],
        total: 0,
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        operator: "",
      },
      typeList: [],
      typeMap: [],
      tableData: [],
      handleType: "",
      handleDetailType: "",
      runningTime: [],
      airDetailForm: {},
      productFormRules: {
        disinfectionDate: [
          {
            required: true,
            message: "消毒开始时间不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
      productFormRules2: {
        checkTime: [
          {
            required: true,
            message: "检查日期不能为空",
            trigger: ["blur", "change"],
          },
        ],
        checkContent: [
          {
            required: true,
            message: "检查内容不能为空",
            trigger: ["blur", "change"],
          },
        ],
        actualOperation: [
          {
            required: true,
            message: "实际操作不能为空",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  async created() {
    let params = {
      pageNum: 1,
      pageSize: 10,
    };
    let waterList = await getMaskMachineList(params);
    this.waterList = waterList;
  },
  methods: {
    airDialogClose() {
      this.$refs.airFormRef.resetFields();
    },
    productFormSubmit() {
      this.$refs.airFormRef.validate(async (valid) => {
        if (valid) {
          if (this.handleType === "add") {
            console.log(this.airForm, "this.airForm");
            await addMaskMachineDetail(this.airForm);
          } else if (this.handleType === "update") {
            let params = this.airForm;
            delete params.status
            delete params.reviewer
            delete params.verifyTime
            await updateMaskMachineDetail(params);
          }
          this.waterList = await getMaskMachineList(this.searchForm);
          this.airDialogVisible = false;
        }
      });
    },
    productFormSubmit2() {
      this.$refs.productFormRef.validate(async (valid) => {
        this.airDetailForm.airId = this.airForm.id;
        if (valid) {
          if (this.handleDetailType === "add") {
            let data = await addMaskMachineDetail(this.airDetailForm);
            let list = this.airForm.airDetailList;
            list.push(data);
            this.airForm.airDetailList = list;
          } else if (this.handleDetailType === "update") {
            let list = this.airForm.airDetailList;
            console.log(this.airDetailForm, "this.airDetailForm");
            delete this.airDetailForm.index;
            delete this.airDetailForm.inputType;
            list.splice(this.airDetailForm.index, 1, this.airDetailForm);
            this.airForm.airDetailList = list;
          }
          this.innerVisible = false;
        }
      });
    },
    handleAdd() {
      this.airFormTitle = "新增口罩机清洁记录";
      this.handleType = "add";
      this.airDialogVisible = true;
    },
    handleDelete(index, row) {
      this.$confirm("确认删除？", "提示", {
        type: "warning",
      }).then(async () => {
        await deleteMaskMachineList(row.id);
        if (this.productPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--;
        }
        this.waterList = await getMaskMachineList(this.searchForm);
      });
    },
    handleConform(id) {
      this.$confirm("确认复核通过？", "提示", {
        type: "warning",
      }).then(async () => {
        let params = {
          id: id,
        };
        await confirmMaskMachineList(params);
        getMaskMachineInfoDetail(id).then((res) => {
          this.airForm = { ...res };
        });
      });
    },
    handleUpdate(index, row) {
      this.airFormTitle = "口罩机清洁记录";
      this.handleType = "update";
      getMaskMachineInfoDetail(row.id).then((res) => {
        this.airForm = { ...res };
        this.airDialogVisible = true;
      });
    },
    handleInfo(index, row) {
      this.airFormTitle = "口罩机清洁记录";
      this.handleType = "detail";
      getMaskMachineInfoDetail(row.id).then((res) => {
        this.airForm = { ...res };
        this.airDialogVisible = true;
      });
    },
    handleSearch() {
      this.searchForm.pageNum = 1;
      getMaskMachineList(this.searchForm).then((res) => {
        console.log(res, "res");
        this.waterList = res;
      });
    },
    pageNumChange(pageNum) {
      this.searchForm.pageNum = pageNum;
      getMaskMachineList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    pageSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize;
      this.searchForm.pageNum = 1;
      getMaskMachineList(this.searchForm).then((res) => {
        this.waterList = res;
      });
    },
    handleInfo2(index, row) {
      this.handleType = "detail";
      getMaskMachineInfoDetail(row.id).then((res) => {
        this.airForm.id = res.id;
        this.airForm.deviceNo = res.deviceNo;
        this.airForm.deviceName = res.deviceName;
        this.airForm.status = res.status;
        let {
          disinfectionObject,
          disinfectionDate,
          cleaningTime,
          startTime,
          endTime,
          releaseWater,
          operator,
          confirmor,
          remarks,
          runCondition,
        } = res;
        let tempArr = [
          {
            disinfectionObject,
            disinfectionDate,
            cleaningTime,
            startTime,
            endTime,
            releaseWater,
            operator,
            confirmor,
            remarks,
            runCondition,
          },
        ];
        this.airForm.airDetailList = tempArr;
        this.runningTime = [res.startupTime || "", res.offTime || ""];
        this.innerVisible = true;
      });
    },
  },

  watch: {
    runningTime(val, oldVal) {
      this.airForm.startTime = val ? val[0] : "";
      this.airForm.endTime = val ? val[1] : "";
    },
    airDialogVisible(val){
      if(!val){
        this.airForm = {}
        this.runningTime = []
      }
    }
  },
};
</script>

<style>
#product section {
  margin: 0px 0px 20px 20px;
}
</style>
